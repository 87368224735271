/* You can add global styles to this file, and also import other style files */
// @import './assets/scss/theme/background';
// @import './assets/scss/theme/worko/worko.scss';

// Note: This is Bootstrap version 4.6.2
@import '../src/assets/libs/bootstrap/css/bootstrap.min.css';

// @import '~slick-carousel/slick/slick.css';
// @import '~slick-carousel/slick/slick-theme.css';




html,
body {
    height: 100%;
}

body {
    margin: 0;
    font-family: Roboto, "Helvetica Neue", sans-serif;
}

/* Importing Bootstrap SCSS file. */
@import 'bootstrap/scss/bootstrap';





// header css end
:root {
    /* fonts */
    --font-inria-sans: "Inria Sans";
    --font-poppins: Poppins;
    --font-poller-one: "Poller One";

    /* font sizes */
    --font-size-7xl: 1.63rem;
    --font-size-13xl: 2rem;
    --font-size-5xl: 1.5rem;
    --font-size-41xl: 3.75rem;
    --font-size-35xl: 3.38rem;
    --font-size-lg: 1.13rem;
    --font-size-xl: 1.25rem;
    --font-size-17xl: 2.25rem;
    --font-size-26xl: 2.81rem;
    --font-size-15xl: 2.13rem;
    --font-size-3xl: 1.38rem;
    --font-size-11xl: 1.88rem;

    /* Colors */
    --color-white: #fff;
    --color-black: #000;
    --color-gray-100: #fffefe;
    --color-gray-200: #7b888b;
    --color-gray-300: #061634;
    --color-gray-400: rgba(123, 136, 139, 0.4);
    --color-lightgray-100: rgba(214, 213, 213, 0.4);
    --color-skyblue-100: #57e1ff;
    --color-darkslategray-100: #004759;
    --color-darkslategray-200: #20324b;
    --color-darkslategray-300: rgba(24, 43, 69, 0.99);
    --color-modal-bg: #0A1E36;
    --color-aliceblue: #e5ebf2;
    --color-deepskyblue-100: rgba(0, 194, 255, 0.4);
    --color-deepskyblue-200: rgba(0, 210, 255, 0.4);
    --color-deepskyblue-300: rgba(0, 210, 255, 0.5);
    --color-teal-100: #3a7783;
    --color-teal-200: #09758b;
    --color-cadetblue: #5baabe;
    --color-gainsboro: rgba(227, 227, 227, 0.4);

    /* Paddings */
    --padding-8xs: 0.31rem;
    --padding-19xl: 2.38rem;

    /* Border radiuses */
    --br-7xs: 6px;
    --br-11xl: 30px;
    --br-31xl: 50px;
    --br-xl: 20px;
    --br-162xl: 181px;
}

body {
    font-family: var(--font-poppins) !important;
    background: linear-gradient(147.55deg, #000000 34.1%, #182B45 70.16%);
    color: #fff;
}

.fa-heart {
    color: #ff0018 !important;
}

// .font-inria {
//     font-family: var(--font-inria-sans) !important;
// }

// For paginator dropdown
.styled-dropdown option {
    background-color: transparent !important;
    /* transparent background */
    color: black !important;
    /* black text for options */
    font-size: 16px;
}

.btn-dark {
    outline: none !important;
    box-shadow: none !important;
    color: rgba(87, 225, 255, 1);
    background-color: transparent;
    border-radius: 20px !important;
    padding: 10px 45px;
    border: 5px solid rgba(87, 225, 255, 1);
    font-size: 32px;

    &.active {
        color: rgba(6, 22, 52, 1) !important;
        background-color: rgba(87, 225, 255, 1) !important;
        border-color: rgba(87, 225, 255, 1) !important;

    }

    &:hover {
        color: rgba(87, 225, 255, 1) !important;
        background-color: transparent !important;
    }

    &:focus {
        color: rgba(6, 22, 52, 1) !important;
        background-color: rgba(87, 225, 255, 1) !important;
    }
}

.btn-light {
    outline: none !important;
    box-shadow: none !important;
    color: rgba(87, 225, 255, 1);
    background-color: transparent !important;
    border-radius: 20px !important;
    padding: 10px 45px;
    border: 5px solid rgba(87, 225, 255, 1) !important;
    font-size: 32px;
    margin-left: 50px;

    &:hover {
        color: #061634 !important;
        background-color: rgba(87, 225, 255, 1) !important;
        border-color: rgba(87, 225, 255, 1) !important;
    }

    &.active {
        color: #061634 !important;
        background-color: rgba(87, 225, 255, 1) !important;
        border-color: rgba(87, 225, 255, 1) !important;

    }

    &:focus {
        color: rgba(87, 225, 255, 1) !important;
        background-color: transparent !important;
        border: 5px solid rgba(87, 225, 255, 1) !important;
    }
}

.py-130 {
    padding: 130px 0;
}

.pb-130 {
    padding-bottom: 130px;
}

// .dropdown-menu {
//     border: none;
//     box-shadow: 0 1px 15px 0 rgb(79 195 245 / 61%);
//     border-radius: 10px;
//     display: block;
//     transform: translate(12px, 12px);
//     transition: .2s ease-in-out;
//     opacity: 0;
//     pointer-events: none;
//     padding: 0;

//     &::after {
//         display: inline-block;
//         margin-left: 0.255em;
//         vertical-align: 0.255em;
//         content: "";
//         border-top: 1em solid;
//         border-right: 1em solid transparent;
//         border-bottom: 0;
//         border-left: 1em solid transparent;
//         position: absolute;
//         top: -12px;
//         right: 15px;
//         color: #fff;
//         transform: rotate(180deg);
//         z-index: -1;
//     }

//     .dropdown-item {
//         padding: 0.8em 1.5rem;
//         transition: .5s;
//         border-radius: 10px;

//         &:hover {
//             background-color: #50D5FF;
//             background-color: #010e26;
//             color: #fff;
//         }
//     }

//     &.show {
//         transform: translate(12px, 6px);
//         opacity: 1;
//         pointer-events: inherit;
//     }
// }

.section-head {
    font-size: 60px;
    font-weight: 700;
    line-height: 90px;
    letter-spacing: 0em;
    background: -webkit-linear-gradient(270deg, #50D5FF 12.5%, #D1E4FB 76.04%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;

}

.sub-head {
    font-size: 28px;
    font-weight: 500;
    line-height: 40px;
}

.vector-wrap {
    position: absolute;
    top: 290px;
    left: 6.22rem;
    z-index: -1;
    pointer-events: none;

    .icon {
        transform: translate(-34px, 30px);
    }

    .inner-vector-wrap {
        position: absolute;
        bottom: 190px;

        .icon {
            transform: translate(35px, 8px);
        }
    }
}

.rectangle_one {
    position: absolute;
    top: 111%;
    right: -5%;
    border-radius: var(--br-162xl);
    background: linear-gradient(245.67deg,
            rgba(8, 110, 154, 0.58),
            rgba(24, 69, 53, 0) 98.98%);
    width: 76.76rem;
    height: 39.08rem;
    transform: rotate(-36.5deg);
    transform-origin: 0 0;
    z-index: 1;
    pointer-events: none;
    user-select: none;
}

.rectangle_two {
    position: absolute;
    top: 106%;
    right: -7%;
    border-radius: var(--br-162xl);
    background: linear-gradient(245.67deg,
            rgba(8, 110, 154, 0.17),
            rgba(24, 69, 53, 0) 98.98%);
    width: 76.76rem;
    height: 39.08rem;
    transform: rotate(-36.5deg);
    transform-origin: 0 0;
    pointer-events: none;
    user-select: none;

    &.bottom_left {
        right: 70%;
        top: auto;
        bottom: -185%;
    }

}

.circel-radial-gradient {
    position: absolute;
    right: 0;
    top: 0;
    z-index: 1;
    pointer-events: none;
    user-select: none;

    img {
        // max-width: 977px;
    }

    &.bottom_right {
        bottom: -500px;
        top: auto;
        opacity: 0.8;
    }

    &.bottom_center {
        top: 50%;
        transform: translateY(-50%);
    }

    &.left_center {
        top: 50%;
        transform: translateY(-50%);
        left: 0;
    }
}

// header css start


header {
    position: relative;
    z-index: 2;


    .top-header-bg {
        position: absolute;
        top: 0;
        left: 0;
        user-select: none;

        img {
            max-width: 425px;
        }
    }

    .navbar-brand {
        img {
            width: 195px;
        }
    }

    .navbar-nav {
        .nav-item {
            a {
                &.nav-link {
                    color: #fff;
                    font-size: 30px;
                    font-weight: 400;
                    // padding-right: 3rem;
                    padding-left: 3rem;
                }
            }
        }
    }

    @media (max-width: 1030px) and (min-width: 500px) {
        .navbar-nav {
            .nav-item {
                a {
                    &.nav-link {
                        font-size: 18px;
                        font-weight: 400;
                        // padding-right: 3rem;
                        padding-left: 3rem;
                    }
                }
            }
        }


    }


    @media (max-width: 575px) {}
}

// home banner css

.container-wrap {
    width: calc(100% - 175px);
    max-width: 1920px;
    padding-left: 15px;
    padding-right: 15px;
    margin-left: 175px;
}

div#globeViz {
    position: absolute;
    overflow: hidden;
    right: 0;
    top: -150px;
    z-index: 0;

    .scene-container {
        transform: translateX(50%);

        canvas {
            &+div {
                overflow: inherit !important;
            }
        }

    }

}

.globe-content-wtap {
    width: 325px;
    position: absolute;
    bottom: 30px;
    left: -150px;
    padding: 25px 40px 60px;
    border-radius: 18px;
    background: url("assets/images/content-bg-img.png") no-repeat;

    // pointer-events: none;
    .heading {
        font-size: 22px;
        font-weight: 500;
        line-height: 33px;
        color: #fff;
        margin-top: 5px;

    }

    p {
        font-size: 14px;
        font-weight: 500;
        line-height: 21px;
        color: #C3C3C3;
        font-weight: 300;
        height: 85px;
        overflow: hidden;
    }

    .img-wrap {
        width: 50px;
        height: 50px;
        border-radius: 50px;
        display: block;
        background-color: #000;
        background-size: cover;

        &.left-img {
            background: url("assets/images/man.png") no-repeat;
        }

        &.right-img {
            background: url("assets/images/icons_google.png") no-repeat;
        }
    }
}

.home-banner {
    position: relative;

    .circel-radial-gradient {
        &.right_circel {
            img {
                transform: translateY(-10%);
            }
        }
    }

    .earth-map {
        position: absolute;
        right: 0%;
        top: 0;

        img {
            max-width: 85%;
            transform: translate(70px, 65px);
        }
    }

    .text-area {
        .main-head {
            // height: 178px;
            font-size: 39px;
            line-height: 65px;
            font-weight: 700;
            background: -webkit-linear-gradient(45deg, #4FC3F5 9%, #D1E4FB 90%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
        }

        ul {
            li {
                font-size: 24px;
                font-weight: 500;
                line-height: 40px;
                color: rgba(249, 230, 230, 1);
            }

        }

    }

    .counters {
        z-index: 1;
        // background-image: url("assets/icons/counters-bg.png");
        background-position: center;
        padding: 100px 20px;
        margin-top: 150px;
        margin-left: 150px;

        // background-repeat: no-repeat;
        // background-size: 105% 105%;
        .counters-bg {
            position: absolute;
            top: 0;
            left: 0;
            user-select: none;
            pointer-events: none;
            width: 100%;
            height: 100%;
        }

        strong {
            font-size: 5.2vw;
            font-weight: 600;
            line-height: 135px;
            letter-spacing: 0em;
        }

        p {
            font-size: 2.08vw;
            font-weight: 600;
            line-height: 54px;
            letter-spacing: 0em;
            color: #7B898B;
        }
    }
}

.solution-section {
    position: relative;

    .container-wrap {
        margin-left: 86px;
    }

    p {
        font-size: 24px;
        line-height: 36px;
        max-width: 650px;
    }

    .solution-info-wrap {
        max-width: 587px;

        p {
            font-size: 22px;
            line-height: 33px;
            font-weight: 300;
            max-width: 100%;
        }
    }

    .vector-wrap {
        top: 0;

        .inner-vector-wrap {
            bottom: auto;
            top: 50%;
            transform: translateY(-50%);
        }
    }


    .top-header {
        padding-left: 89px;

    }

    .slide {
        padding-left: 89px;

        img {
            max-height: 550px;
        }

        .vector-wrap {
            top: 0;
            height: 100%;
            left: -89px;
        }
    }

}

.benefits-section {
    position: relative;



    .container-wrap {
        margin-left: 86px;
    }

    .circel-radial-gradient {
        &.bottom_right {
            overflow: hidden;

            img {
                transform: translateX(50%);
            }
        }
    }

    .btn {
        border-radius: 20px;
        background: #fff;
        color: #061634;
        border-radius: 20px;
        padding: 2px 15px;
        min-width: 232px;
        font-size: 26px;
        border: 5px solid transparent;

        &:hover {
            background: transparent !important;
            color: #fff;
            border: 5px solid #fff;
        }
    }



    p {
        font-size: 24px;
        line-height: 36px;
        max-width: 650px;
        margin-bottom: 70px;

    }

    .vector-wrap {
        top: 0;

        .inner-vector-wrap {
            bottom: auto;
            top: 50%;
            transform: translateY(-50%);

            .icon {
                transform: translate(35px, 0px);
            }
        }
    }

    .top-header {
        padding-left: 89px;

    }

    .slide {
        padding-left: 150px;

        .vector-wrap {
            top: 0;
            height: 100%;
            left: -150px;
        }
    }

    .benefits-list {
        .list-item {
            box-shadow: 10px 8px 20px 0px #FFFFFF57;
            background: #5BAABE;
            color: #fff;
            border-radius: 30px;
            padding: 15px;
            margin-bottom: 40px;
            max-width: 610px;
            min-height: 80px;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 19px;

            &:nth-child(1) {
                transform: translateX(160px);
            }

            &:nth-child(2) {
                background: #3A7683;
                transform: translateX(80px);

            }

            &:nth-child(3) {
                background: #3A7683;
            }

            &:nth-child(4) {
                background: #004759;
                transform: translateX(80px);
            }

            &:nth-child(5) {
                background: #09758B;
                transform: translateX(160px);
            }
        }
    }

    .radio-head-text {
        position: absolute;
        left: 0;
        z-index: 2;
        pointer-events: none;
    }

    .radio-btn-wrap {
        position: relative;
        padding-right: 8.5vw;
    }

    .radio-btn-outer {
        padding: 80px;
        border-radius: 50%;
        position: relative;
        transition: 20s;
        cursor: pointer;

        // &:after {
        //     width: 100%;
        //     height: 100%;
        //     position: absolute;
        //     background-image: url("assets/images/radio-ellipse.png");
        //     background-repeat: no-repeat;
        //     background-size: contain;
        //     background-position: 0 6px;
        //     top: 0%;
        //     left: 0%;
        //     content: '';
        //     border-radius: 50%;
        //     z-index: 1;
        //     transition: 20s;
        //     pointer-events: none;
        // }


        // &:hover {
        //     &:after {
        //         animation: circle 2s linear infinity;
        //     }
        // }

        &:focus {
            .radio-btn {
                transform: scale(.95)
            }
        }

        .radio-btn {
            background: linear-gradient(181.58deg, rgba(40, 67, 105, 0.67) 22.75%, #0CBADA 101.12%);
            width: 331.48px;
            height: 325.24px;
            box-shadow: 0px 10px 10px 0px #00000040 inset;
            box-shadow: 30px 10px 4px 0px #00000040;
            border-radius: 50%;
            font-size: 34px;
            line-height: 51px;
            font-weight: 700;
            display: flex;
            justify-content: center;
            align-items: center;
            position: relative;
            padding: 35px;
            overflow: hidden;

            span {
                position: relative;
                z-index: 1;
                transform: translateY(100%);
                transition: 2s;
            }

            &:after {
                width: 80%;
                height: 80%;
                position: absolute;
                top: 10%;
                left: 10%;
                background: linear-gradient(92deg, rgb(40 67 105 / 0%) 0, #182b45 236.12%);
                content: '';
                border-radius: 50%;

            }


        }
    }

    .benefits-info-wrap {
        transform: translateY(100%);
        transition: 2s;
    }

    .slick-active {
        .benefits-info-wrap {
            transform: translateY(0) !important;
        }

        .radio-btn {
            span {
                transform: translateY(0) !important;
            }
        }
    }

    .radio-list-wrap {
        position: absolute;
        right: 0;
        top: 50%;
        transform: translate(-0px, -50%);

        li {
            font-size: 36px;
            font-weight: 700;
            line-height: 54px;
            color: rgba(0, 194, 255, 0.4);
            padding: 20px 0;
            white-space: nowrap;

            &:nth-child(1) {
                transform: translateX(-50px);
            }

            &:nth-child(2) {
                transform: translateX(-25px);
                color: rgba(0, 210, 255, 0.4)
            }

            &:nth-child(3) {
                color: rgba(123, 137, 139, 0.4);
            }

            &:nth-child(4) {
                color: rgba(214, 213, 213, 0.4);
                transform: translateX(-25px);
            }

            &:nth-child(5) {
                color: rgba(227, 227, 227, 0.4);
                transform: translateX(-50px);
            }


        }

        &.employee-list {
            li {
                &:nth-child(1) {
                    transform: translateX(-10px);
                }

                &:nth-child(2) {
                    color: rgba(123, 137, 139, 0.4);
                }

                &:nth-child(2) {
                    color: rgba(0, 210, 255, 0.4);
                    transform: translateX(-10px);
                }
            }
        }
    }

    .slick-active {
        // .radio-btn-outer {
        //     &:after {
        //         animation: circle 2s linear;
        //     }
        // }

        .radio-head-text {
            animation: circle 2s linear;
        }
    }

    // .rotation_anti_clock {
    //     .radio-btn-outer {
    //         &:after {
    //             animation: anti_clock_circle 1s linear;
    //         }
    //     }
    //     .radio-head-text {
    //         animation: anti_clock_circle 1s linear;
    //     }
    // }

}

@keyframes circle {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

@keyframes anti_clock_circle {
    from {
        transform: rotate(360deg);
    }

    to {
        transform: rotate(0deg);
    }
}

.companies-section {
    position: relative;

    .vector-wrap {
        top: 0;
        transform: scaleY(-1);
    }

    .btn.bg-white {
        border-radius: 20px;
        background: #fff;
        color: #061634;
        border-radius: 20px;
        padding: 2px 15px;
        font-size: 26px;
        border: 5px solid transparent;
        min-width: 232px;

        &:hover {
            background: transparent !important;
            color: #fff;
            border: 5px solid #fff;
        }
    }

    .section-head {
        font-size: 36px;
        font-weight: 600;
        line-height: 54px;
    }

    .companies-list {
        .slick-list {
            padding-top: 100px !important;
            padding-bottom: 100px !important;

            .slick-track {
                display: flex;
                align-items: center;
                background-color: #fff;

            }
        }

        .slick-arrow {
            width: 190px;
            height: 38%;

            &::before {
                display: none;
            }
        }

        .slick-prev {
            background: linear-gradient(90deg, #0E0E0E 8.18%, rgba(14, 14, 14, 0) 97.5%);
            z-index: 1;
        }

        .slick-next {
            background: linear-gradient(-90deg, #0E0E0E 8.18%, rgba(14, 14, 14, 0) 97.5%);
            right: 0;
            left: auto;
        }

        .list-item {
            position: relative;
            cursor: pointer;

            .companies-info {
                background-color: rgba(255, 255, 255, 1);
                width: 285px;
                box-shadow: 26px 4px 4px 0px rgba(0, 0, 0, 0.25);
                border: 2px solid rgba(87, 225, 255, 1);
                border-radius: 30px;
                padding: 15px;
                position: absolute;
                top: 50%;
                left: 50%;
                z-index: 2;
                opacity: 0;
                pointer-events: none;
                transform: scale(.9) translate(-50%, -50%);
                transition: 1s cubic-bezier(0.075, 0.82, 0.165, 1);

                p {
                    font-size: 18px;
                    font-weight: 700;
                    line-height: 27px;
                    color: #565656;
                    max-width: 215px;
                    margin: 5px auto 10px;
                }

                // .btn-dark {
                //     color: #fff;
                //     padding: 5px;
                //     display: block;
                //     font-size: 18px;
                //     font-weight: 700;
                //     line-height: 27px;
                //     border-radius: 50px;
                //     width: 100%;
                //     background-color: rgba(87, 225, 255, 1);

                //     &:hover {
                //         background-color: #20324B;
                //         border-color: #20324B;
                //     }
                // }

            }

            &:hover {
                .companies-info {
                    opacity: 1;
                    pointer-events: initial;
                    transform: scale(1) translate(-50%, -50%);
                }

            }
        }
    }
}

.video-section {
    position: relative;

    .video-wrap {
        box-shadow: 20px 15px 60px 0px #00A3FFD4;
        max-width: 1009px;
        border-radius: 20px;
        background: #fff;

        .video-head-text {
            min-height: 695px;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 54px;
            font-weight: 700;
            line-height: 81px;
            background: linear-gradient(90deg, rgba(24, 24, 24, 0.51) -3.11%, #D1E4FB 91.16%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
        }
    }

    .vector-wrap {
        transform: scaleY(-1);
    }
}

.testimonial-section {
    position: relative;
    overflow: hidden;
    padding-top: 100px;

    // Edited by KA

    padding-bottom: 180px;
    // End of edit by KA

    .rectangle_one {
        right: auto;
        left: -100px;
        top: 0;
        transform: rotate(20deg);
        background: linear-gradient(125deg, #43b7ff -40.12%, rgba(255, 255, 255, 0) 56.11%);
        border-radius: 0;
    }

    .testimonial-wrap {
        border-radius: 59px;
        background: #fff;
        padding: 80px 0 80px 50px;

        .section-head {
            background: linear-gradient(90deg, #505050 8.31%, #001B3C 63.78%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
        }

        .testimonial-list {
            .slick-track {
                margin-left: -10px;
                margin-right: -10px;
                display: flex;
                align-items: flex-end;
            }

            .testimonial-list-item-wrap {
                padding: 100px 10px;

                .testimonial-list-item {
                    box-shadow: 10px 15px 4px 0px #00000040;
                    border: 3px solid #FFFFFF;
                    background: linear-gradient(0deg, #1E3B63, #1E3B63);
                    transition: cubic-bezier(0.075, 0.82, 0.165, 1);
                    border-radius: 50px;
                    padding: 25px;

                    .list-head {
                        font-size: 18px;
                        font-weight: 700;
                        line-height: 27px;
                    }

                    p {
                        font-size: 18px;
                        line-height: 27px;
                        font-weight: 300;
                    }

                }

                &.slick-center {
                    .testimonial-list-item {
                        box-shadow: 25px 25px 4px 0px #00000040;
                        background: linear-gradient(0deg, rgba(24, 43, 69, 0.99), rgba(24, 43, 69, 0.99)),
                            linear-gradient(0deg, #FFFFFF, #FFFFFF);

                        .list-head {
                            font-size: 23px;
                            line-height: 35px;
                        }
                    }
                }

            }

            .slick-dots {

                // bottom: -100px;
                li {
                    width: 50px;
                    height: 50px;

                    button {
                        border-radius: 50px;
                        width: 50px;
                        height: 50px;
                        background: #17273C4D;
                        box-shadow: 6px 8px 4px 0px #00000040;
                        transition: cubic-bezier(0.075, 0.82, 0.165, 1);

                        &:before {
                            display: none;
                        }
                    }

                    &.slick-active {
                        button {
                            background: #17273C;
                        }
                    }
                }
            }

            .btn-dark {
                box-shadow: 10px 10px 4px 0px #00000040;
                background: #20324B;
                color: #fff;
                border-color: #20324B;

                &:hover {
                    background: #fff;
                    color: #20324B;
                    border-color: #20324B;
                }

            }

            .btn-light {
                box-shadow: 10px 10px 4px 0px #00000040;
                background: #fff;
                color: #20324B;
                border-color: #20324B;

                &:hover {
                    background: #20324B;
                    color: #fff;
                    border-color: #20324B;
                }

            }
        }
    }
}


footer {
    overflow: hidden;
    position: relative;

    // Edited by KA

    // padding-top: 180px;

    //End of edit: Commented out the padding 

    .footer-content {
        background-color: #fff;
    }

    .footer-text {
        .footer-head-text {
            font-weight: 700;
            color: var(--color-darkslategray-300);
        }

        p {
            font-size: 20px;
            color: rgba(113, 113, 113, 0.99);
        }
    }

    .copy-rights {
        font-weight: 500;
        color: rgba(24, 43, 69, 0.99);
    }

    .list-head {
        font-size: 26px;
        font-weight: 500;
        color: rgba(24, 43, 69, 0.99);
    }

    .footer-links {
        li {
            a {
                display: block;
                padding: 5px 0;
                font-size: 20px;
                color: #57E1FF;
            }
        }
    }
}


// Edited by KA
.footer-contact {
    padding-left: 30% !important;
}

.footer-container {
    max-width: 2200px;
    // margin: auto;
}

@media (max-width: 992px) {
    .footer-contact {
        padding-left: 0px !important;
    }
}

//-------- End of edit: -------- 


// // candidate-dash -----------------------------------------------------------------------------//
.btn-light-blue {
    background: #57E1FF;
    color: #FFF;
}

.req-missed-img {
    width: 51%;
}

// Candidate-dashboard//
.btn-next,
.btn-secondary,
.btn-secondary:hover,
.btn-next:hover {
    background-color: #36D0FF;
    width: 108px;
    height: 35px;
    border-radius: 9px;
    border: unset;
    text-align: center;
    color: #000
}


.candidate-card {
    border-radius: 18px;
    background: #0A1E35;
    // width: 100%;
    height: 100%;
}

.issue-btn {

    background-color: transparent;
    width: 118px;
    height: 35px;
    background: #0A111C;
    border-radius: 22px;
    border: unset;
    font-size: 10px;
    color: white;

}

.form-input {
    border: 1px solid white;
    color: white;
    background-color: transparent;
    font-size: 11px;
    border-radius: 10px;
}

// .content-container {
//     height: calc(100vh - 60px);
// }

.divide-45 {
    flex: 1;
}

.divide-55 {
    flex: 2;
    height: 100%;
}

.img-user {
    max-width: 100%;
}

.mentor-card {
    border-radius: 12px;
    background: #132C52;
}

.mentor-content {
    color: #FFF;
    font-family: Poppins;
    font-size: 14px;
    font-style: italic;
    font-weight: 400;
    line-height: normal;
    text-align: center;
}

.mentor-img {
    width: 100px;
    text-align: center;
}

.company-content {
    color: #FFF;
    font-family: Poppins;
    font-size: 10.198px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.designation-content {
    color: #FFF;
    font-family: Poppins;
    font-size: 11.198px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.profile-link {
    color: #69DCFF;
    font-family: Poppins;
    font-size: 10.958px;
    font-style: italic;
    font-weight: 300;
    line-height: normal;
    text-decoration-line: underline;
}

.about-content {
    color: #FFF;
    font-family: Poppins;
    font-size: 19.244px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.about-details {
    color: #FFF;
    text-align: center;
    font-family: Poppins;
    font-size: 9.958px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.mentor-text {
    color: white;
    font-size: 8px;
}

.edit-profile-text {
    color: #FFF;
    text-align: center;
    font-family: Poppins;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

// .candidate-img{
//     width: 150px;
// }

.about-textarea {
    border: 1px solid white;
    border-radius: 12px;
    // border: 1px solid #E5EAF1;
    background: #0A1E35;
    color: #FFF;
}


// Employee-dashboard css -----------------------------------------------------------------------//

.form-lable {
    font-size: 12px;
    color: #FFF;
}

// Employee-dashboard css -----------------------------------------------------------------------//

.employee-dash-btn {
    border-radius: 3.364px;
    background: #50D5FF;
    font-size: 12px;
    // width:200px;
}

.employee-dash-btn {
    color: none !important;
    background-color: none !important;
    border-color: none !important;
}

.employee-reject-btn {
    border-radius: 100px;
    border: 1px solid #36D0FF;
    background: #0A111C;
    color: white;
    width: 91px;
}

.employee-accept-btn {
    border-radius: 100px;
    border: 1px solid #36D0FF;
    background: #2D8EC2;
    width: 91px;
    color: white;
}

.btn-mark-done {
    background-color: transparent;

    border-radius: 22px;
    // border: unset;
    font-size: 10px;
    color: grey;
    // border-radius: 100px;
    border: 1px solid #36D0FF;
    opacity: 0.5;
}

.btn-earning {
    border-radius: 104px;
    border: 1px solid #36D0FF;
    background: #2D8EC2;
    // font-size: px;
    color: #FFF;
    font-size: 13px;
    text-align: center;
    width: auto;
    line-height: normal;
}

.selector {
    border-radius: 8px;
    border: 1.047px solid #E5EAF1;
    height: 30.492px;
    justify-content: center;
    align-items: center;
    color: #FFF;
    font-size: 12px;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23FFFFFF' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e");
    width: 100%;
    background-color: #0A1E36;
}

.text-white {
    color: #FFF !important;
}

.text-gray {
    color: #999999 !important;
}

.package-card {
    border-radius: 20px;
    background: #0A1E35;
}

.add-icon {
    border: 2px solid rgba(87, 225, 255, 1);
    border-radius: 50%;
    background-color: transparent;
    font-size: 12px;


}


// public-profile//
.package-card {
    border-radius: 10px;
    border: 2px solid #295CA8;
    background-color: transparent;
}

.-pointer {
    cursor: pointer;
}

// admin dashboard //
.admin-table-main {


    table {
        width: 100%;
    }

    .table-wrapper {
        width: 100%;
        /* Set the desired width */
        overflow-x: auto;
        /* Enable horizontal scrollbar when necessary */
        opacity: 10px;
        background-color: #0A1E35;
    }

    /* Adjust the style for the table if needed */
    .table-style {
        /* Your existing table styles */
    }

    .table-wrapper::-webkit-scrollbar-thumb {
        background-color: rgba(41, 92, 168, 0.7);
        /* Color with opacity */
        border-radius: 6px;
        /* Roundness of the scrollbar thumb */
    }

    .table-wrapper::-webkit-scrollbar-track {
        background: transparent;
        /* Background of the scrollbar track */
    }

    .mat-mdc-form-field {
        font-size: 6px;
        width: 100%;
    }

    .mat-mdc-paginator-outer-container {
        color: cornflowerblue;
        background: #0A1E35;
    }

    .mat-mdc-paginator-page-size-select {
        margin: 0 4px;
        width: 83px;
        border: 2px solid #295CA8;

        border-radius: 3px;
    }

    .mat-mdc-table tbody,
    .mat-mdc-table tfoot,
    .mat-mdc-table thead,
    .mat-mdc-cell,
    .mat-mdc-footer-cell,
    .mat-mdc-header-row,
    .mat-mdc-row,
    .mat-mdc-footer-row,
    .mat-mdc-table .mat-mdc-header-cell {
        // background: inherit;
        color: white;
    }

    .mat-mdc-text-field-wrapper,
    .mdc-text-field,
    .ng-tns-c1205077789-0,
    .mdc-text-field--filled {
        background-color: #0A1E35;
    }

    .mat-mdc-text-field-wrapper,
    .mdc-text-field,
    .ng-tns-c1205077789-0,
    .mdc-text-field--filled {
        color: white;
        background-color: #0A1E35;

    }

    .mat-mdc-paginator-icon svg {
        display: inline-block;
        width: 100px;
        fill: #295CA8;

    }

    .mat-mdc-paginator-icon {
        fill: #295CA8;
    }

    // .mat-mdc-icon-button svg {
    //     fill: #295CA8;
    // }
    .mat-mdc-icon-button svg {
        fill: #fff;
        margin-bottom: 97%;
    }

    .mat-mdc-table {
        // --mat-table-row-item-outline-width: 1px;
        // table-layout: auto;
        // white-space: normal;
        /* background-color: var(--mat-table-background-color); */
        background-color: #0A1E35;
    }

    .mat-mdc-icon-button .mat-mdc-paginator-icon {
        fill: #295CA8;
    }

    .mat-mdc-paginator-range-label {
        color: cornflowerblue;
    }

    .mat-mdc-select-value {
        color: cornflowerblue;
    }

    ::ng-deep .mat-mdc-select-arrow {
        color: cornflowerblue;
    }

    .ng-tns {
        color: cornflowerblue;
    }

    .mat-mdc-select-arrow svg {
        //  fill: currentColor;
        position: absolute;
        top: 50%;
        left: 50%;
        // transform: translate(-50%, -50%);
        color: cornflowerblue;
    }

    .mat-mdc-header-row .mdc-data-table__header-row .cdk-header-row .ng-star-inserted {
        border: 2px solid #476baf !important;
    }

    .table-color {
        background-color: #0A1E35 !important;
    }

    td,
    th {
        width: 25%;
        // color: white;
        background-color: #0A1E35;

    }

    .dropdown-btn {
        background-color: transparent;
        border: 2px solid #295CA8;
        color: white;
    }

    .dropdown-menu,
    .show {
        background-color: #0A1E35;
        border: 2px solid #295CA8;
        color: white;
    }

    .dropdown-item {
        color: white;
    }

    .dropdown-menu,
    li {
        font-size: small;
    }

    .table-tabs,
    .active {
        color: white;
        font-size: 14px;
        // background-color: #50D5FF !important;
    }

    .table-tabs {
        border: 2px solid #295CA8 !important;
        font-size: 14px;
        // background-color: #0A1E35 !important;
    }

    .search-container {
        position: relative;
        // display: inline-block;
        // width: 100%; 
    }

    .admin-custom-clear-button {
        right: 10px;
        position: absolute;
        transform: translateY(50%);
        background-color: transparent;
        color: #FFFFFF;
        border: none;
        border-radius: 50%;
        height: 24px;
    }

    .serachInput {
        // background-color: #0A1E35 !important;
        // border: 2px solid #295CA8 !important;
        // padding: 5px;
        // border-radius: 3px;
        // font-size: small;
        // color: white !important;
        // height: 46px;
        background-color: #0A1E35 !important;
        border: 2px solid #295CA8 !important;
        padding: 5px;
        padding-right: 40px;
        /* Ensure space for the clear button on the right */
        border-radius: 3px;
        font-size: small;
        color: white !important;
        height: 46px;
    }

    .content-style {
        font-size: 8x !important;
    }

    .mdc-data-table__content {
        background-color: #0A1E35;
    }

    .small-column {
        width: 50px;
        // color: white;


    }

    .mdc-text-field--filled:not(.mdc-text-field--disabled) {
        background-color: transparent !important;
        border: 2px solid #476baf;
        color: white;
    }

    .mat-mdc-input-element,
    .ng-tns-c1205077789-0,
    .mat-mdc-form-field-input-control,
    .mdc-text-field__input,
    .cdk-text-field-autofill-monitored {
        color: white !important;
    }

    ::placeholder {
        color: white !important;
    }

    .mat-mdc-select-panel-above div.mat-mdc-select-panel {
        border: 2px solid #295CA8 !important;
        color: white !important;
        transform-origin: bottom center;
        background: transparent;
    }

    .searchButton {
        background-color: #50D5FF !important;
        border: 2px solid #0A1E35 !important;
        padding: 4px;
        border-radius: 5px;
        height: 46px;

    }

    .mat-mdc-option.mdc-list-item {
        align-items: center;
        color: white !important;
    }

    .mat-mdc-row,
    .cdk-row {
        border-top: 2px solid #295CA8;
        border-bottom: 2px solid #295CA8;
    }

    .mat-sort-header-arrow,
    .ng-trigger,
    .ng-trigger-arrowPosition,
    .ng-tns-c1267148319-3,
    .ng-star-inserted {
        color: white;
    }
}

.pe-2 mat-form-field {
    // max-width: 150px;
    max-width: 165px;
    max-height: 69px;

}

.selector {
    width: 191px;
}

.mat-mdc-text-field-wrapper .mat-mdc-form-field-infix .mat-mdc-floating-label {
    top: auto;
    font-size: small;
}



.required-form-field:before {
    content: "*";
    color: red;
    padding-right: 5px;
}

.fs-12 {
    font-size: 12px;
}

.bg-black {
    background-color: #000000;
}

/**************CSS FOR TERMS OF SERVICE PAGE START ******************/
.individual-page .top-header-bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 36%;
    max-width: 500px;

    img {
        width: 100%;
    }
}

.individual-page .gradient {
    margin-left: -8.5%;
    margin-right: -8.1%;
    opacity: 100%;
}

.individual-page .gradient-left1 {
    top: -250px;
    left: 0;
}

.individual-page .gradient-left2 {
    top: 200px;
    left: 0;
}

.individual-page .gradient-right1 {
    right: 0;
    // top: -425px;
    // right: 0;
    margin-right: 0;
    top: -200px;
}

.individual-page .gradient-right2 {
    right: 0;
    top: 300px;
}

.individual-page .gradient-left3 {
    top: 50px;
    left: 0;
}

.individual-page .gradient-right3 {
    right: 0;
}

.individual-page .gradient-sm {
    display: none;
}

// .individual-page .all{
//     background-color: #000000;
// }


.individual-page .section {
    // background-color: #061221;
    overflow: hidden;
    padding: 9rem 7% 2rem;
}



.individual-page h1 {
    padding-bottom: 2rem;
    letter-spacing: 0em;
    background: -webkit-linear-gradient(270deg, #50D5FF 12.5%, #D1E4FB 76.04%);
    background-clip: border-box;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-weight: 700;
}

.individual-page h2 {
    font-size: 20px;
    line-height: 24px;
    margin: 0 0 1.5rem;
    font-weight: 700;
    padding-top: 1.5rem;
}

.individual-page .text {
    font-size: 16px;
    line-height: 24px;
    margin: 0 0 16px;
}

.individual-page ul li {
    font-size: 16px;
    line-height: 24px;
    list-style: none;
    padding-bottom: 0.8rem;
}

@media (max-width: 1025px) and (min-width: 480px) {

    .individual-page .section {
        padding: 7rem 5% 1rem;
    }

    .individual-page .top-header-bg {
        width: calc(200px + 24vw);

        img {
            width: 80%;
        }
    }

    .individual-page h2 {
        font-size: 19px;
    }

    .individual-page .gradient {
        width: 75%;
        margin-left: -6.5%;
        margin-right: -5.55%;
        opacity: 90%;

        img {
            width: 100%;
        }
    }

    .individual-page .gradient-sm {
        display: none;
    }

    .individual-page .gradient-left1 {
        top: 50px;
        left: 0;
    }

    .individual-page .gradient-left2 {
        top: 500px;
        left: 0;
    }

    .individual-page .gradient-right1 {
        right: 0;
        top: -450px;
        margin-right: 0;
    }

    .individual-page .gradient-right2 {
        right: 0;
        top: 300px;
    }

}

@media (max-width: 480px) {

    .individual-page .top-header-bg {
        width: 261px;

        img {
            width: 80%;
        }
    }

    .individual-page .section {
        padding: 7rem 5% 1rem;
    }

    .individual-page h1 {
        padding-bottom: 1rem;
    }

    .individual-page .gradient {
        width: 100%;
        margin-left: -6.5%;
        margin-right: -5.5%;
        opacity: 100%;

        img {
            width: 100%;
        }
    }

    .individual-page .gradient-sm {
        display: block !important;
        opacity: 65%;
    }

    .individual-page .gradient-left1 {
        top: 50px;
        left: 0;
    }

    .individual-page .gradient-left2 {
        // top: 200px;
        left: 0;
    }

    .individual-page .gradient-right1 {
        right: 0;
        top: -250px;
        opacity: 100%;
        margin-right: 0;
    }

    .individual-page .gradient-right2 {
        right: 0;
        top: -100px;
    }


}

/**************CSS FOR TERMS OF SERVICE PAGE END ******************/

// Index page css: KA
.index-section {
    background-color: #000;
    background: linear-gradient(to bottom right, #000 30%, #1E2C3F 80%);
}

.index-section .para {
    font-size: calc(1.3vw + 8px);
    font-size: calc(1vw + 8px);
    font-weight: 400;
    line-height: 160%;
    letter-spacing: 0em;
    text-align: left;
}

.index-section .para2 {
    font-size: 24px;
}

.index-section hr {
    height: 2px;
    width: 100%;
    background-color: #09111C;
    border: none;
}

// .index-section .about-section {
//     position: relative;
//     background-color: #000000;
// }

.index-section .head1 {
    font-weight: 400;
}

.index-section .buttons {
    z-index: 1;
    position: relative;
}

.index-section .btn-dark {
    background-color: rgb(87, 225, 225) !important;
    color: #182b45 !important;
    font-size: calc(1vw + 14px);
    padding: 0.8rem 6%;
    border: 5px solid rgba(87, 225, 255, 1) !important;
}

.index-section .btn-dark:hover {
    // color: rgb(87, 225, 255) !important;
    // background-color: transparent !important;
    border-color: rgb(69, 185, 210) !important;
}

.index-section .btn-light {
    font-size: calc(1vw + 14px);
    color: rgb(87, 225, 225);
    padding: 0.8rem 6%;
    margin-left: 0px;
}

.index-section .left-btn {
    margin-right: 30px;
}

.index-section .title {
    font-size: 3.2vw;
    font-size: calc(2vw + 21px);
    font-weight: 700;
    line-height: 140%;
    letter-spacing: 0em;
    background: -webkit-linear-gradient(270deg, #50D5FF 12.5%, #D1E4FB 76.04%);
    background-clip: border-box;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.index-section .grad {
    position: absolute;
    // opacity: 75%;
}

.index-section .grad-left1 {
    left: -19%;
}

.index-section .grad-right1 {
    top: 0;
    right: 0;
}

.index-section .grad-left2 {
    //left: 0; removed code for timeline
    left: -4.7rem
}

.index-section .grad-right2 {
    right: 0;
    top: -17rem;
}

.index-section .grad-right3 {
    right: 0;
    top: -5rem;
}

.index-section .grad-right4 {
    right: -8%;
    top: -22rem;
}

.index-section .top-header-bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 36%;
    max-width: 500px;
}

.index-section .top-header-bg img {
    width: 100%;
}

.index-section .content-icon img {
    width: 100%;
}

.index-section .side-timeline {
    left: 7.5%;
    margin-top: 5px;
}

.index-section .side-timeline img {
    // width: calc(225px + 5vw);
    //    width: calc(206px + 6vw);
    width: calc(199px + 6vw);
    // max-width: 310px;
}

.index-section .side-timeline-md {
    display: none;
}

.index-section .side-timeline-sm {
    display: none;
}

// .index-section{
//     background-color: #000;
//     background: linear-gradient(to bottom right, #000 30%, #1E2C3F 70%);
// }

.index-section .rows {
    // padding-left: 16%; removing code for timeline
}

.index-section .intro {
    // padding-top: 4rem;   removing code for timeline
    padding-top: 1rem;
    position: relative;

    .content {
        width: 65%;
        padding-top: 10rem;
    }
}

.index-section .intro-image {
    position: absolute;
    right: 0;
    width: 28.2%;

    img {
        width: 100%;
    }
}

.index-section .intro-ul {
    padding: 2rem 5%;
    // width: 110%; remove code for timeline
}

.index-section .stats-section {
    justify-content: space-between;
    padding-top: 10rem;
    // padding-left: 21%; removing code for timeline
    width: 98%;

    .line-right {
        border-right: 1px solid #fff;
        height: 61px;
        opacity: 36%;
    }

    .line-right1 {
        padding-left: 8%;
    }
}

.index-section .stat {
    width: 30%;
    display: flex;
    align-items: center;

    .stat-title {
        color: black;
        -webkit-text-stroke: 2px #57E1FF;
        background: none;
        font-size: calc(3vw + 24px);
        font-weight: 700;
    }

    .stat-content {
        padding-top: 0.5rem;
        text-align: start;
        font-size: calc(1vw + 8px);
        padding-left: 7%;
    }

}

.index-section .title2 {
    font-weight: 700;
    font-size: calc(16px + 1.5vw);
}

.index-section .flex-sm {
    display: none;
}

// .index-section .purpose-section {
//     padding-left: 18%;
//     padding-top: 16rem;

//     .purpose-header {
//         width: 68%;
//     }
//     .purpose-content {
//         text-align: center;
//         font-size: calc(1vw + 7px);
//     }
// }

// .index-section .peer {
//     position: relative;
//     justify-content: space-around;
//     padding-top: 5rem;

//     .contents {
//         width: 58%;
//     }

//     .peer-img {
//         width: 39%;
//         padding-top: 6rem;
//         img {
//             width: 100%;
//         }
//     }
// }

// .index-section .interview-title {
//     padding-top: 15rem;
// }

// .index-section .feedback {
//     padding-top: 10rem;
//     position: relative;

//     .image {
//         width: 150%;
//         padding-top: 2rem;
//         img {
//             width: 100%;
//         }
//     }
// }

// .index-section .career-section {
//     padding-top: 15rem;
//     width: 120%;
// }

// .index-section .career-content {}

// .index-section .feedback img {
//     margin-right: -10%;
// }

// .index-section .smart {
//     position: relative;

//     .contents {
//         width: 60%;
//     }
//     .smart-title {
//         padding-top: 8rem;
//     }

//     .smart-img {
//         position: absolute;
//         right: 3%;
//         top: 15rem;
//         width: 38%;
//         img {
//             width: 100%;
//         }
//     }
// }

// .index-section .access-title {
//     padding-top: 10rem;
// }

// .index-section .access-content {
//     width: 88%;
//     margin: auto;
// }

// /* Benefits Section */

// .index-section .benefits-section {
//     padding-left: 16%;
//     padding-top: 15rem;

//     .benefits-content {
//         padding-top: 2rem;
//         width: 90%;
//     }
// }

// .index-section .left-col {
//     padding-left: 2.5%;
// }

// .index-section .referee-section {
//     position: relative;
//     padding-top: 3rem;
//     padding-left: 3%;
// }

// .index-section .ref {
//     display: flex;
//     justify-content: center;
//     align-items: center;
//     text-align: center;
//     border: none;
//     border-radius: 100px;
//     width: 47%;
//     height: 78px;
//     padding: 0 5%;

//     margin-top: 3.4%;
//     font-size: calc(1vw + 5px);
//     line-height: 123%;
// }

// .index-section .ref1 {
//     background-color: #5BAABE;
//     margin-left: 7%;
// }

// .index-section .ref2 {
//     background-color: #5BAABE;
//     margin-left: 3%;
// }

// .index-section .ref3 {
//     background-color: #3A7683;
// }

// .index-section .ref4 {
//     background-color: #004759;
//     margin-left: 3%;
// }

// .index-section .ref5 {
//     background-color: #09758B;
//     margin-left: 7%;
// }

// .index-section .right-col {
//     width: 45%;
//     display: flex;
//     position: absolute;
//     right: 4%;
//     top: 9rem;
// }

// .index-section .semi {
//     width: 75%;
//     position: absolute;
//     right: 15%;

//     img {
//         width: 100%;
//     }
// }

// .index-section .semi-md {
//     display: none;
// }

// .index-section .refer-circle {
//     width: 50%;
//     border-radius: 50%;
//     background-color: #09758B;
//     margin-top: 9%;
//     position: absolute;
//     right: 27%;
//     text-align: center;
//     /* top: 1rem; */
//     top: 3%;
//     padding-bottom: 50%;

//     p {
//         position: absolute;
//         top: 50%;
//         left: 50%;
//         transform: translate(-50%, -50%);
//         margin: 0;
//         font-size: calc(1.5vw + 8px);
//         font-weight: 700;
//         width: 65%;
//     }
// }

// .index-section .options {
//     font-size: calc(10px + 1.5vw);
//     font-weight: 700;
//     margin-top: -1rem;
//     padding-left: 55%;

//     .opt {
//         padding-bottom: 40%;
//     }

//     .opt1 {
//         color: #50D5FF;
//         padding-left: 10%;
//     }
//     .opt2 {
//         color: #50D5FF;
//         padding-left: 81%;
//     }
//     .opt3 {
//         color: #7B898B;
//         padding-left: 102%;
//     }
//     .opt4 {
//         color: #313738;
//         padding-left: 81%;
//     }
//     .opt5 {
//         color: #7B898B;
//         padding-left: 10%;
//     }
// }


// /* Candidate CSS */
// .index-section .candidate-section {
//     padding-top: 10rem;
//     padding-left: 16%;
//     position: relative;

//     .benefits-content {
//         padding-top: 2rem;
//         width: 90%;
//     }
// }

// .index-section .candidate-col {
//     width: 45%;
//     display: flex;
//     position: absolute;
//     right: 1%;
//     top: 30%;

//     .candidate-circle {
//         right: 27%;
//         top: 3.5%;

//         p {
//             width: 58%;
//             text-align: center;
//         }
//     }
// }

// .index-section .options-md {
//     display: none;
// }

// .index-section .candidate-options {
//     margin-top: -4rem;
//     padding-left: 48%;
//     width: 100%;

//     .opt {
//         padding-bottom: 10%;
//     }

//     .opt1,
//     .opt2,
//     .opt3 {
//         opacity: 40%;
//     }
//     .opt1 {
//         color: #00C2FF;
//         padding-left: 7%;
//     }
//     .opt2 {
//         color: #00C2FF;
//         padding-left: 24%;
//     }
//     .opt3 {
//         color: #00C2FF;
//         padding-left: 50%;
//     }
//     .opt4 {
//         color: #313738;
//         padding-left: 71%;
//     }
//     .opt5 {
//         color: #313738;
//         padding-left: 81%;
//     }
//     .opt6 {
//         color: #313738;
//         padding-left: 54%;
//     }
//     .opt7 {
//         color: #7B898B;
//         padding-left: 32%;
//     }
//     .opt8 {
//         color: #7B898B;
//         padding-left: 7%;
//     }
// }

.index-section .companies-section {
    padding-top: 11rem;
    overflow: hidden;

    .slide-container {
        background-color: #fff;
        margin-top: 5rem; //added code for timeline
    }

    .company-content {

        .title,
        .para {
            text-align: center;
            font-size: calc(1.5vw + 10px);
        }
    }

    .company-slides {
        // background-color: #fff;
        // margin-top: 9rem;
        // height: calc(7vw + 40px);
        height: 130px;
        // width: 126%;
        margin-left: -9%;
        display: flex;
        align-items: center;

        .company {
            position: relative;
            margin-right: 130px;

            .hover {
                position: absolute;
                background: white;
                width: 250px;
                // top: -75px;
                height: 225px;
                // right: -75px;
                z-index: 1;
                border-radius: 8px;
                padding-top: 20px;

                .logo-container {
                    display: flex;
                    justify-content: center;
                    margin-bottom: 0.5rem;
                }

                .button {
                    // font-size: calc(0.5vw + 8px);
                    margin: 0;
                    color: #fff;
                    background-color: #194561;
                    width: 190px;
                    padding: 6px;
                    margin-top: 7px;
                    // margin-left: 30px;
                    font-size: 0.9rem;

                }
            }

            img {
                height: 75px;
                width: 75px;
                color: black;
            }
        }
    }

    button {
        // padding: 0.5rem 10%;
        margin-top: 7rem;
        // width: 30%;
        // font-size: calc(1.2vw + 7px);
        // margin-left: 34%;
        border-radius: 8px;
        padding: 0.5rem 50px;
        font-size: 1.5rem;
        // margin-left: 12%;
    }
}

.index-section .hover1,
.index-section .hover2,
.index-section .hover3,
.index-section .hover4,
.index-section .hover5,
.index-section .hover6,
.index-section .hover7 {
    display: none;
}

.index-section .company1:hover {
    .hover1 {
        display: block !important;
    }
}


.index-section .company2:hover {
    .hover2 {
        display: block !important;
    }
}


.index-section .company3:hover {
    .hover3 {
        display: block !important;
    }
}


.index-section .company4:hover {
    .hover4 {
        display: block !important;
    }
}


.index-section .company5:hover {
    .hover5 {
        display: block !important;
    }
}


.index-section .company6:hover {
    .hover6 {
        display: block !important;
    }
}

.index-section .company7:hover {
    .hover7 {
        display: block !important;
    }
}

.index-section .info-section {
    padding-top: 7rem;
    padding-left: 4%;
    justify-content: space-around;

    .video-img {
        width: 70%;

        img {
            width: 100%;
        }
    }
}

.index-section .video-img-md {
    display: none;
}

.index-section .info {
    padding-left: 2%;
}

.index-section .info-title {
    font-size: calc(1.5vw + 14px);
    font-weight: 700;
}

.index-section .reviews-section {
    background-color: #e7f4fc;
    color: #182b45;
    margin-top: 3rem;
    width: 93%;
    margin-left: 3%;
    border-radius: 30px;
    padding-bottom: 5rem;
    margin-bottom: 2.5rem;
    position: relative;

    .review-img {
        width: 27%;

        img {
            width: 100%;
        }
    }

    overflow: hidden;
}

.index-section .reviews-content {
    display: flex;
    flex-direction: column;
    width: 80%;
    padding-left: 12%;
    text-align: center;
    padding-top: 6rem;

    .title {
        font-size: calc(1vw + 7px);
        line-height: 167%;
    }

    .sub-title {
        font-size: calc(2vw + 20px);
        font-weight: 700;
    }

    .text {
        width: 80%;
        margin: 2rem auto;
    }
}

.index-section .review-cards {
    overflow: hidden;
    width: 100vw;
    padding-top: 4rem;
    justify-content: space-between;
    margin-left: -3%;
}

.index-section .review-card {
    background-color: #fff;
    border-radius: 33px;
    /* margin-right: 3.3%; */
    width: 31.5%;
    padding: 4rem 3% 0.5rem;

    /* .card-img{
        top: -4rem;
        left: 35%;
    } */

    .card-content {
        font-size: 14px;
        text-align: center;
    }
}

.index-section .reviewer {
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: center;
    text-align: center;

    .name {
        font-weight: 700;
        font-size: 20px;
        padding-top: 2rem;
    }
}

.index-section .review-button {
    display: flex;
    width: 100%;
    z-index: 100;
    position: relative;
}

.index-section .arrow-button {
    border-radius: 50%;
    background-color: #000;
    margin-top: 5rem;
    padding: 1rem 1.3rem;
}

.index-section .left {
    margin-left: 45%;
}

.index-section .right {
    margin-left: 2%;
}

.index-section .review-cards-md {
    display: none;
}

.index-section .review-cards-sm {
    display: none;
}

/* Review section for carousel */

.index-section .reviews-section {
    background-color: #e7f4fc;
    color: #182b45;
    margin-top: 3rem;
    width: 93%;
    margin-left: 3%;
    border-radius: 30px;
    padding-bottom: 5rem;
    margin-bottom: 2.5rem;

    .review-img {
        width: 27%;

        img {
            width: 100%;
        }
    }

    overflow: hidden;
}

.index-section .reviews-content {
    display: flex;
    flex-direction: column;
    width: 80%;
    padding-left: 12%;
    text-align: center;
    padding-top: 6rem;

    .title {
        font-size: calc(1vw + 7px);
        line-height: 167%;
    }

    .sub-title {
        font-size: calc(2vw + 20px);
        font-weight: 700;
    }

    .text {
        width: 80%;
        margin: 2rem auto;
    }
}

.index-section .review-cards {
    display: flex;
    overflow: hidden;
    width: 575vw;
    padding-top: 4rem;
    justify-content: space-between;
    transition: margin-left 0.8s ease;
}

.index-section .review-card {
    background-color: #fff;
    border-radius: 33px;
    /* margin-right: 3.3%; */
    width: 31.5%;
    /* padding: 4rem 2%; */
    padding: 1% 0.5% 0.1%;

    margin-right: 0.5%;

    .card-img {
        width: 100%;
        top: -4rem;
        left: 0;
        display: flex;
        justify-content: center;
    }

    .card-content {
        font-size: 14px;
        text-align: center;
    }
}

.index-section .reviewer {
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: center;
    text-align: center;

    .name {
        font-weight: 700;
        font-size: 20px;
        padding-top: 2rem;
    }
}

// .index-section .review-button {
//     display: flex;
//     width: 100%;
// }

.index-section .arrow-button {
    border-radius: 50%;
    background-color: #000;
    margin-top: 3rem;
    padding: 1rem 1.3rem;
    cursor: pointer;
}

.index-section .arrow-button.disabled {
    opacity: 0.7;
    pointer-events: none;
    cursor: default;
}

.index-section .left {
    margin-left: 45%;
}

.index-section .right {
    margin-left: 2%;
}

.index-section .review-cards-md {
    display: none;
}

.index-section .review-cards-sm {
    display: none;
}

html,
body {
    height: 100%;
}

body {
    margin: 0;
    font-family: Roboto, "Helvetica Neue", sans-serif;
}

.btn-next,
.btn-secondary,
.btn-secondary:hover,
.btn-next:hover {
    background-color: #36D0FF;
    width: 108px;
    height: 35px;
    border-radius: 9px;
    border: unset;
    text-align: center;
    color: #000
}

// Material Table footer css

.pagination-controls {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin: 0px 0;
    gap: 10px;
}

.prev-button,
.next-button {
    color: black;
    border: none;
    padding: 8px 16px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
    /* Space between text and icon */

    background-color: #50c7f3;
    /* Light blue base color */
    // border: 2px solid #0a1e35; /* Dark border for contrast */
    border-radius: 6px;
    height: 44px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    /* Subtle shadow for depth */
    font-size: 16px;
    transition: background-color 0.3s ease, transform 0.2s ease;
}

/* Hover effect for enabled buttons */
.prev-button:hover:not(:disabled),
.next-button:hover:not(:disabled) {
    // background-color: #4297d4;
    color: white;
    // transform: translateY(-2px);
    box-shadow: 0px 6px 8px rgba(0, 0, 0, 0.15);
    /* Enhanced shadow */
}

/* Active button effect */
.prev-button:active,
.next-button:active {
    // transform: translateY(0);
    // background-color: #365a92;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.2);
    /* Stronger shadow */
}

/* Disabled state */
.prev-button:disabled,
.next-button:disabled {
    background-color: #e0e0e0;
    /* Light gray for disabled */
    color: #888;
    /* Muted text color */
    cursor: not-allowed;
    border-color: #ccc;
    /* Match disabled gray */
    box-shadow: none;
    /* Remove shadow for disabled state */
}

/* focus outline for accessibility */
.prev-button:focus,
.next-button:focus {
    outline: 2px solid #50c7f3;
    /* Blue focus outline */
    outline-offset: 2px;
}


.pagination-controls label {
    margin-right: 8px;
    font-size: 14px;
}

/* Dropdown container for alignment */
.dropdown-container {
    display: flex;
    align-items: center;
    gap: 10px;
    font-family: Arial, sans-serif;
}

/* Label styling */
.dropdown-label {
    font-size: 12px !important;
    color: white;
    font-weight: 500;
    margin: 0 !important;
}

/* Wrapper for the dropdown */
.dropdown-wrapper {
    position: relative;
    width: 85px;
}

/* Styled dropdown */
.styled-dropdown {
    height: 44px;
    width: 100%;
    padding: 8px 12px;
    font-size: small;
    color: white;
    /* Text color */
    background-color: transparent;
    /* Dropdown background */
    border: 2px solid #476baf;
    /* Border color */
    border-radius: 6px;
    appearance: none;
    /* Removes default browser styles */
    cursor: pointer;
    outline: none;
    background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="24" width="24" viewBox="0 0 24 24"><path fill="%23476baf" d="M7 10l5 5 5-5H7z"/></svg>');
    background-repeat: no-repeat;
    background-position: right 10px center;
    background-size: 22px;
}

/* Hover and focus effects */
.styled-dropdown:hover {
    border-color: #334f8c;
    /* Darker blue on hover */
}

.styled-dropdown:focus {
    border-color: #334f8c;
    box-shadow: 0 0 4px rgba(71, 107, 175, 0.5);
}